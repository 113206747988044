<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The mass spectrometric signal measured for standard concentrations of methane in hydrogen is
        given below:
      </p>

      <p class="mb-2">
        <v-simple-table>
          <thead>
            <th>
              <chemical-latex content="CH4" />
              <stemble-latex content="$(Vol\%)$" />
            </th>
            <th>
              Signal
              <stemble-latex content="$(mV)$" />
            </th>
          </thead>

          <tbody>
            <tr>
              <td>
                <stemble-latex content="$0.0620$" />
              </td>
              <td>
                <stemble-latex content="$38.40$" />
              </td>
            </tr>
            <tr>
              <td>
                <stemble-latex content="$0.122$" />
              </td>
              <td>
                <stemble-latex content="$86.50$" />
              </td>
            </tr>
            <tr>
              <td>
                <stemble-latex content="$0.245$" />
              </td>
              <td>
                <stemble-latex content="$184.7$" />
              </td>
            </tr>
            <tr>
              <td>
                <stemble-latex content="$0.486$" />
              </td>
              <td>
                <stemble-latex content="$378.4$" />
              </td>
            </tr>
            <tr>
              <td>
                <stemble-latex content="$0.971$" />
              </td>
              <td>
                <stemble-latex content="$803.4$" />
              </td>
            </tr>
            <tr>
              <td>
                <stemble-latex content="$1.921$" />
              </td>
              <td>
                <stemble-latex content="$1662.89$" />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </p>

      <p class="mb-2">
        (a) Perform a linear regression by using the method of least squares to determine the
        equation of the calibration curve.
      </p>
      <p class="mb-5">
        <s-textarea v-model="inputs.input1" outlined />
      </p>

      <p class="mb-2">
        (b) Determine the uncertainty in the slope, intercept and linear regression.
      </p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-5"
        prepend-text="$\text{Slope uncertainty}:$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input3"
        class="mb-5"
        prepend-text="$\text{Intercept uncertainty}:$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.input4"
        class="mb-5"
        prepend-text="$\text{Linear regression}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        (c) Four analyses of an unknown mixture of methane and hydrogen gave a signal of
        <stemble-latex content="$249.50\,\pm\,0.40\text{mV}$" />
        . By using the information in parts (a) and (b), determine the concentration of
        <chemical-latex content="CH4" />
        in the unknown mixture. Include error in your answer.
      </p>
      <calculation-input
        v-model="inputs.input5"
        class="mb-5"
        prepend-text="$\text{Concentration}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUPEI2210A2Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
    };
  },
};
</script>
